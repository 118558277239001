// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-templates-code-js": () => import("./../../../src/templates/code.js" /* webpackChunkName: "component---src-templates-code-js" */),
  "component---src-templates-email-index-page-js": () => import("./../../../src/templates/emailIndexPage.js" /* webpackChunkName: "component---src-templates-email-index-page-js" */),
  "component---src-templates-preview-js": () => import("./../../../src/templates/preview.js" /* webpackChunkName: "component---src-templates-preview-js" */),
  "component---src-templates-rendered-js": () => import("./../../../src/templates/rendered.js" /* webpackChunkName: "component---src-templates-rendered-js" */)
}

